<template>
  <Modal title="User Subscription" ref="subModal" @opened="initData">
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="input-blocks">
            <label>User</label>
            <input
              v-if="props.userId"
              :value="subscription.user_name"
              disabled
              type="text"
              class="form-control"
            />
            <vue-select
              v-else
              tabindex="1"
              class="w-100"
              id="users"
              :options="users"
              required
              name="user"
              v-model="subscription.user_id"
              @select="everSub(subscription.user_id)"
            />
          </div>
        </div>

        <div class="col-lg-12">
          <div class="input-blocks">
            <label>Service Type</label>
            <vue-select
              class="w-100"
              id="plans"
              :options="SubPlanArray"
              required
              name="subscription_plan"
              v-model="subscription.type"
              @select="fetchServiceType(subscription.type)"
            />
          </div>
        </div>

        
        
        
        <div class="col-lg-12" v-if="subscription.type">
          <div class="input-blocks">
            <label>Plan Name</label>
            <vue-select
              class="w-100"
              id="plan_names"
              :options="plan_names"
              required
              name="subscription_plan"
              v-model="subscription.plan_id"
              @select="fetchSessions(subscription.type, subscription.plan_id)"
            />
          </div>
        </div>

        <div class="col-lg-12" v-if="subscription.plan_id">
          <div class="input-blocks">
            <label>Period</label>
            <vue-select
              class="w-100"
              id="periods"
              :options="periods"
              required
              name="subscription_plan"
              v-model="subscription.session_id"
            />
          </div>
        </div>

        <div class="col-lg-12" v-if="subscription.session_id">
          <div class="input-blocks">
            <label>Start Date</label>
            <input
              v-if="props.userId"
              :value="subscription.user_name"
              disabled
              type="date"
              class="form-control"
            />
            <input
              v-else
              tabindex="1"
              type="date"
              class="form-control"
              v-model="subscription.start_date"
              required
            />
          </div>
        </div>
        <!-- <div class="col-lg-12" v-if="subscription.session_id && ever_Sub == false">
          <div class="input-blocks">
            <label>Existing Subscriber</label>
            <input
              v-if="props.userId"
              :value="subscription.user_name"
              disabled
              type="text"
              class="form-control"
            />
            <vue-select
              v-else
              tabindex="1"
              class="w-100"
              id="charge"
              :options="option"
              required
              name="user"
              v-model="subscription.new_subscriber"
            />
          </div>
        </div> -->

        <div class="col-lg-12" v-if="ever_Sub == false">
           <div class="input-blocks">
            <label>End Date</label>
            <input
              v-if="props.userId"
              :value="subscription.user_name"
              disabled
              type="date"
              class="form-control"
            />
            <input
              v-else
              tabindex="1"
              type="date"
              class="form-control"
              v-model="subscription.end_date"
              required
            />
          </div> 
        </div>

        <div class="col-lg-12">
          <div class="input-blocks d-flex align-items-center">
            <label for="old_member" class="mb-0">Old Member</label>
            <a-switch
            style="left: 310px;"
              :checked="subscription.old_member"
              @click="oldMember()"
            />
          </div>
        </div>
        <!-- <div class="col-lg-6">
          <div class="input-blocks">
            <label>Created At</label>
            <input
              type="date"
              class="w-100 form-control"
              id="plans"
              :options="plans"
              required
              name="subscription_plan"
              v-model="subscription.created_at"
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-blocks">
            <label>Expires At</label>
            <input
              type="date"
              class="w-100 form-control"
              id="plans"
              :options="plans"
              required
              name="subscription_plan"
              v-model="subscription.expires_at"
            />
          </div>
        </div> -->
      </div>
      <div class="modal-footer-btn">
        <button type="button" class="btn btn-cancel me-2" @click="subModal.closeModal()">
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-submit"
          id="submit"
          @click="saveSubscription()"
        >
          Submit
        </button>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import {
  ref,
  computed,
  watch,
  onMounted,
  defineProps,
  defineExpose,
  getCurrentInstance,
} from "vue";
import { checkInputValidity } from "@/utils/validator";
import { useSubscriptionStore } from "@/store/subscriptionStore";
import { useUserStore } from "@/store/userStore";
import { usePlanStore } from "@/store/planStore";
import Modal from "@/components/modal/modal.vue";
import { DatePicker, Select } from "ant-design-vue";
import dayjs from "dayjs";
import { format2RawDate } from "@/utils/date";
import { plans } from "@/constants/enum";

// Props
const props = defineProps({
  userId: String,
});
const { appContext } = getCurrentInstance();
const toCase = appContext.config.globalProperties.$toCase;

// Refs
const subModal = ref(null);

// Stores
const subscriptionStore = useSubscriptionStore();
const userStore = useUserStore();
const planStore = usePlanStore();
const ever_Sub = ref(null);

// Computed properties

const option = ref(["Yes"]);
const users = computed(() =>
  userStore.users
    .filter((user) => user.subscription == false)
    .map((user) => ({
      text: user.name,
      id: user.id,
    }))
);



const SubPlanArray = ["Gym", "Massage", "Trainer"];
const subPlans = computed(() =>
  SubPlanArray.map((plan, index) => ({
    text: plan,
    id: index,
  }))
);

// const periods = computed(() => {
//   const type =
//     subscription.value.type.charAt(0).toLowerCase() + subscription.value.type.slice(1);
//   console.log(type);
//   switch (type) {
//     case plans.Gym:
//       return planStore.GymPlans.map((plan) => ({
//         text: plan.gym_plan_name,
//         id: plan.gym_plan_id,
//       }));

//     case plans.Massage:
//       return planStore.MassagePlans.map((plan) => ({
//         text: plan.massage_plan_name,
//         id: plan.massage_plan_id,
//       }));

//     case plans.Trainer:
//       return planStore.TrainerPlans.map((plan) => ({
//         text: plan.trainer_plan_name,
//         id: plan.trainer_plan_id,
//       }));
//   }
// });

const plan_names = ref([]);
const periods = ref([]);
// Reactive subscription data
const subscription = ref({
  user_id: null,
  plan_id: null,
  session_id: null,
  type: "",
  end_date: null,
  start_date_date: null,
  old_member: false,
});

// Watchers
watch(
  () => props.userId,
  (newUserId) => {
    if (newUserId) {
      const user = subscriptionStore.subscriptions().find((user) => user.id == newUserId);
      fillData(user);
    } else {
      resetData();
    }
  },
  { immediate: true, deep: true }
);

watch(
  subscription,
  () => {
    setTimeout(() => checkInputValidity("submit"), 5);
  },
  { deep: true }
);

function addHoursToDate(dateString, hoursToAdd = 1) {
  // Create a Date object from the input date string
  let date = new Date(dateString);

  // Add the specified number of hours
  date.setHours(date.getHours() + hoursToAdd);

  // Return the updated date string
  return date.toISOString();
}
function everSub(userId) {
  const user = userStore.users.find((user) => user.id == userId);
  console.log(user);
  if (user && user.ever_subscribed) {
    ever_Sub.value = true;
    // alert(true);
    return;
  }
  ever_Sub.value = false;
  // alert(false);
}
// Methods
async function saveSubscription() {
  if (props.userId) {
    subscriptionStore
      .updateSubscription(subscription.value)
      .then(() => subModal.value.closeModal());
  } else {
    // Create new subscription
    
    if(ever_Sub.value){
      delete subscription.value.end_date;
    }
    const status = await subscriptionStore.createSubscription(subscription.value);
    await subscriptionStore.fetchSubscribers();
    await userStore.fetchUsers();
    if (status) {
      subModal.value.closeModal();
    }
  }
}

function resetData() {
  subscription.value = {
    user_id: null,
    plan_id: null,
  };
}

function oldMember(){
  subscription.value.old_member =!subscription.value.old_member;
}

function fillData(user) {
  if (user) {
    subscription.value = {
      user_name: user.name,
      user_id: user.id,
      plan_id: user.subscription.plan_id,
      created_at: format2RawDate(user.subscription.created_at),
      expires_at: format2RawDate(user.subscription.expires_at),
    };
  }
}

function initData() {
  if (!props.userId) {
    resetData();
  }
  checkInputValidity("submit");
}

async function fetchServiceType(serviceType) {
  const type = toCase(serviceType, true, "hh");
  switch (type) {
    case plans.Gym:
      await planStore.fetchGymPlans({ type: type });
      plan_names.value = planStore.GymPlans.map((plan) => ({
        text: plan.gym_plan_name,
        id: plan.gym_plan_id,
      }));
      break;

    default:
      break;
  }
}

async function fetchSessions(serviceType, id) {
  const type = toCase(serviceType, true);
  await planStore.viewMore(id, type);
  switch (type) {
    case plans.Gym:
      periods.value = planStore.GymPlanDetail.gym_periods.map((period) => ({
        text: period.name,
        id: period.gym_plan_period_id,
      }));
      console.log(periods);
      break;
    case plans.Massage:
      periods.value = planStore.MassagePlanDetail;
      break;
    case plans.Trainer:
      periods.value = planStore.TrainerPlanDetail;
      break;

    default:
      break;
  }
}

// Expose methods
defineExpose({ openModal: () => subModal.value.openModal() });

// onMounted()(async () => {
//   alert(plans)
// });
</script>

<style scoped>
.custom-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: #fff;
}

.custom-checkbox:checked {
  background-color: #007bff; /* Matches the theme's primary color */
  border-color: #007bff;
}

.custom-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.input-blocks {
  margin-bottom: 1rem;
}

.input-blocks label {
  font-size: 1rem;
  font-weight: 500;
  color: #343a40;
}

</style>
