<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <div class="page-wrapper">
    <div class="content">
      <div class="page-header">
        <div class="add-item d-flex">
          <div class="page-title">
            <h4>User List</h4>
            <h6>Manage Your Users</h6>
          </div>
        </div>
        <ul class="table-top-head">
          <!-- <li>
            <a data-bs-toggle="tooltip" data-bs-placement="top" title="Refresh"
              ><i data-feather="rotate-ccw" class="feather-rotate-ccw"></i
            ></a>
          </li> -->
          <li>
            <a
              ref="collapseHeader"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Collapse"
              @click="toggleCollapse(collapseHeader)"
            >
              <i data-feather="chevron-up" class="feather-chevron-up"></i>
            </a>
          </li>
        </ul>
        <div class="page-btn d-flex gap-2">
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            @click="openModal('Create')"
          >
            <vue-feather type="plus-circle" class="me-2"></vue-feather>
            Add New User
          </a>
          <a
            href="javascript:void(0);"
            class="btn btn-added"
            style="background-color: black;"
            @click="creditModal()"
          >
            <vue-feather type="plus" class="me-2"></vue-feather>
            Credit User
          </a>
          <!-- <a style="border-color: var(--PRIMARY-COLOR) !important;color: var(--PRIMARY-COLOR) !important;"
            href="javascript:void(0);" class="btn btn-added bg-white" @click="openModal('ADD_SUBSCRIPTION')">
            <vue-feather type="plus-circle" class="me-2"></vue-feather>
            Create Subscription
          </a> -->
        </div>
      </div>
      <!-- USER TABLE -->
      <filter-table
        :extra-class="['table', 'datanew']"
        :columns="userAnalysis.columns"
        :data="userAnalysis.users"
        :filters="userAnalysis.filters"
      >
        <template #header>
          <h4>All Users</h4>
          {{ users }}
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'name'">
            <div class="userimgname gap-2 align-items-center">
              <a href="javascript:void(0);" class="userslist-img bg-img gap-2">
                <Avatar
                  :src="record.profile_picture"
                  :alt="record.name"
                  img-class="user-image"
                />
              </a>
              <div>
                <a href="javascript:void(0);">{{ record.name }}</a>
              </div>
            </div>
          </template>
          <template v-else-if="column.key === 'status'">
            <a-switch
              :checked="record.is_active"
              @change="updateUserStatus($event, record.id)"
            />
          </template>
          <template v-else-if="column.key === 'subscription'">
            <span v-if="record.subscription" :class="getBadgeClass(record.subscription)">Active</span>
            <span v-else :class="getBadgeClass(record.subscription)">inactive</span>
          </template>
          <template v-else-if="column.key === 'account_number'">
            <a
              href="javascript:void(0);"
              @click="openHistoryModal(record.id, record.account_number, record.balance, record.name)"
            >
              {{ record.account_number }}
            </a>
          </template>
          <template v-else-if="column.key === 'action'">
            <td class="action-table-data">
              <div class="edit-delete-action">
                <a
                  class="me-2 p-2 mb-0"
                  href="javascript:void(0);"
                  @click="openModal('View', record.id)"
                >
                  <vue-feather type="eye" class="action-eye"></vue-feather>
                </a>
                <!-- <a class="me-2 p-2 mb-0" @click="openModal('Update', record.id)">
                  <i data-feather="edit" class="feather-edit"></i>
                </a> -->
                <a
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                  @click="deleteUser(record.id)"
                  ><i data-feather="trash-2" class="feather-trash-2"></i>
                </a>
                <a
                  class="me-2 confirm-text p-2 mb-0"
                  href="javascript:void(0);"
                  @click="creditModal(record.account_number, 'action')"
                  ><i data-feather="plus" class="feather-plus"></i>
                </a>
              </div>
            </td>
          </template>
        </template>
      </filter-table>
      <!-- USER TABLE -->
    </div>
  </div>
  <UsersListModal
    :action="userModalAction"
    :user-id="userAnalysis.selectedUserId"
    ref="userModal"
  />
  <CreditModal
    :selected_account_number="selected_account_number" :action="creditModalAction"
  />

  <TransactionHistoryModal :selected_user_id="selected_user_id" :selected_account_number="selected_account_number" :selected_balance="selected_balance" :selected_name="selected_name"/>
  <!-- <UserDetailsModal :user-id="userAnalysis.selectedUserId" v-if="showUserDetailsModal" /> -->
</template>

<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useUserStore } from "@/store/userStore";
import { toggleCollapse } from "@/utils/components";
import Avatar from "@/components/avatar.vue";
import UsersListModal from "./modals/users-list-modal.vue";
import CreditModal from "./modals/credit-modal.vue";
import TransactionHistoryModal from "./modals/transaction-history-modal.vue";
import { confirm } from "@/utils/swal";
// import { PlusIcon } from 'vue-feather-icons'
// store
const userStore = useUserStore();

// data
const userAnalysis = ref({
  filters: ["name", "email"],
  columns: ref([
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: {
        compare: (a, b) => {
          a = a.phone.toLowerCase();
          b = b.phone.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => {
          a = a.email.toLowerCase();
          b = b.email.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Account Number",
      key: "account_number",
      sorter: {
        compare: (a, b) => {
          a = a.account_number.toLowerCase();
          b = b.account_number.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
      sorter: {
        compare: (a, b) => {
          a = a.subscription.toLowerCase();
          b = b.subscription.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: {
        compare: (a, b) => {
          a = a.status.toLowerCase();
          b = b.status.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: "Action",
      key: "action",
      sorter: true,
    },
  ]),
  users: computed(() => userStore.users),
  selectedUserId: ref(null),
});

// refs
const collapseHeader = ref(null);
const selected_account_number = ref(null);
const selected_user_id = ref(null);
const selected_balance = ref(null);
const selected_name = ref(null);
const showUserModal = ref(false);
const showUserDetailsModal = ref(false);
const userModal = ref(null);
const userModalAction = ref(null);
const creditModalAction = ref(null);

// methods
function openModal(action = null, userId = null){
  if (userId != null) {
    userAnalysis.value.selectedUserId = userId;
    userModal.value.openModal();
    userModalAction.value = action
  }
  else{
    userModal.value.openModal();
    userModalAction.value = action
  }
}

function openHistoryModal(user_id, account_number, balance, user_name) {
  selected_user_id.value = user_id;
  selected_account_number.value = account_number;
  selected_balance.value = balance;
  selected_name.value = user_name;
  userStore.fetchUserTransactionHistory(user_id);
  $("#transaction-history-modal").modal("show");
}

function creditModal(account_number = null, action = null) {
  if (account_number != null, action != null) {
    selected_account_number.value = account_number;
    creditModalAction.value = action;
  } else {
    creditModalAction.value = null;
    selected_account_number.value = null;
  }
  $("#credit-modal").modal("show");
}

function getBadgeClass(status) {
  return `badge badge-line${status ? "success" : "danger"}`;
}

async function deleteUser(userId) {
  confirm({
    title: "Are you sure about this?",
    message: "Note that all the data related to this user will be deleted!",
    callback: async (confirmed) => {
      if (confirmed) {
        await userStore.deleteUser(userId);
        await userStore.fetchUsers();
      }
    },
  });
}
async function updateUserStatus(isActive, userId) {
  await userStore.updateUserStatus(userId, { status: isActive ? "active" : "inactive" });
  await userStore.fetchUsers();
}
onMounted(async () => {
  if (userAnalysis.value.users.length == 0) {
    await userStore.fetchUsers();
  }
});
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>